<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" :to="'/'+this.pluralLower">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{this.singular}}: {{this.data.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('paymentType','u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
        <v-row>
          <v-col>
            <v-text-field
                label="Name"
                outlined
                v-model="data.name"
            />
            <v-text-field
                label="Description"
                outlined
                v-model="data.description"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col v-if="isEditable">
          <v-btn @click="update">Save</v-btn>
          <v-btn v-if="isAllowed('paymentType','d')" style="margin-left: 10px;" class="error" @click="deleteData">Delete</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <hr>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h1>Orders</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              :headers="secondaryHeaders"
              :items="data.Users"
              :items-per-page="-1"
              class="elevation-1"
              @click:row="secondaryRowClick"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: {},
        singular: "Payment Type",
        singularLower: "paymenttype",
        plural: "Payment Types",
        pluralLower: "paymenttypes",
        isEditable: false,
        secondaryHeaders: [
          {
            text: 'Customer2',
            align: 'start',
            sortable: true,
            value: 'customerName',
          },
          { text: "Total", value: 'orderTotal'},
          { text: "Items", value: 'numItems'}
        ],
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.data = res.data.data;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed'])
    },
    methods: {
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack(`${this.singular} info Updated 🎉`, "success");
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack(this.singular+" Deleted 🎉", "success");
          await this.$router.go(-1)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      secondaryRowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
    }
  }
</script>
